import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "d44f69ff-47eb-40af-a6eb-2e7f20401773"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsD44f69ff47eb40afA6eb2e7f20401773(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsD44f69ff47eb40afA6eb2e7f20401773", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "545e5018-51b7-4343-b6fc-bd9c6fb94925"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title545e501851b74343B6fcBd9c6fb94925 = "Leveling";

// id: "cee62baa-8edb-4559-a114-cb89139e46f4"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesCee62baa8edb4559A114Cb89139e46f4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesCee62baa8edb4559A114Cb89139e46f4`}>
      {props.children}
    </div>
  );
}

// id: "bd5c6997-74c3-4a75-943b-09217b45b02a"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderBd5c699774c34a75943b09217b45b02a(props: any) {
  return (
    <ShortHeader6e0d5a5a5537416b8c6bB378da3c7713 parentTag="DefaultHeaderBd5c699774c34a75943b09217b45b02a" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "3e75cb32-3020-4dc2-abaa-352ebdf6d36d"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d(props: any) {
  return (
    <ShortHeader6e0d5a5a5537416b8c6bB378da3c7713 parentTag="MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d" header-top={<DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c parentTag="HeaderTop394de0e8D48e4e649dca36e782f1b034" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "3bde8111-a466-4548-bb96-26136db3678e"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody3bde8111A4664548Bb9626136db3678e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody3bde8111A4664548Bb9626136db3678e page-body`}>
      {props["marketingCards"] || <MarketingCardsD44f69ff47eb40afA6eb2e7f20401773 parentTag="MarketingCards5cac7368C4ad4105Afe367bbb6837fca" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "6291cf6f-f142-4505-9413-e40fac2477e2"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage6291cf6fF14245059413E40fac2477e2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage6291cf6fF14245059413E40fac2477e2 page`}>
      {props["header"] || <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="Header596da584D3b741848ad9321de33fa0dd" title="About Us" {...props} />}
      {props["body"] || <MarketingBody3bde8111A4664548Bb9626136db3678e parentTag="BodyE23e1adcD1cc4c9c9a2f80e9c22434a0" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer344d4fff5fd54122Bcc33120750b2e85" {...props} />}
    </div>
  );
}

// id: "ad63f8cf-0e7c-4f94-ae24-a99ef7b4eeec"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageAd63f8cf0e7c4f94Ae24A99ef7b4eeec(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageAd63f8cf0e7c4f94Ae24A99ef7b4eeec`}>
      {props["header"] || <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="Header63fa0359Feca48418d2cF66d61f2b838" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="FooterE458f42fA8d046abB113Fdc4dd5bd80a" {...props} />}
    </div>
  );
}

// id: "a295ec01-e6be-4ed7-bd86-ee8b4403e222"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesA295ec01E6be4ed7Bd86Ee8b4403e222(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesA295ec01E6be4ed7Bd86Ee8b4403e222 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="HeaderDf6ed971555549e9Be45Eaa8fa10320b" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavD109fff701ac4522B381319f06f647a7", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer90780c49A29c4ca2Bf157fdd68a24f72" {...props} />}
    </div>
  );
}

// id: "863c71b5-c5c9-4fcf-9158-7486e450c3b2"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault863c71b5C5c94fcf91587486e450c3b2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault863c71b5C5c94fcf91587486e450c3b2 page`}>
      {props["header"] || <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="Header13934d620b43421f905762b19d35036f" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer4621793aC5744b48A2617333efa22007" {...props} />}
    </div>
  );
}

// id: "20d9df5f-8b09-4552-862a-534fc106a943"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage20d9df5f8b094552862a534fc106a943(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage20d9df5f8b094552862a534fc106a943`}>
      {props["header"] || <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderA5d807e4D47a432eA115638532cb2f25" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer982e2fce97c14d96A1ef83c65c0292f3" {...props} />}
    </div>
  );
}

// id: "c52f5d70-18f1-463f-aa75-7d19544da309"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageC52f5d7018f1463fAa757d19544da309(props: any) {
  return (
    <SecondaryPage20d9df5f8b094552862a534fc106a943 parentTag="ResourcesPageC52f5d7018f1463fAa757d19544da309" header={<SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderD074803fC86a456dBa80Cdff2a69a8a2" title="Resources" />} {...props} />
  );
}

// id: "061c8285-1b91-446f-832c-bf4af44724f6"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label061c82851b91446f832cBf4af44724f6(props: any) {
  return (
    <DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label061c82851b91446f832cBf4af44724f6" {...props} />
  );
}

// id: "9a86e4da-7318-4fc5-ae07-9f58055c685d"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo9a86e4da73184fc5Ae079f58055c685d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qCVORAERKRFjYji_regular.png" className={`${parentTag || ""} DefaultLogo9a86e4da73184fc5Ae079f58055c685d logo`} alt="logo" />
  );
}

// id: "9bc28438-5e2f-4c04-92cf-c339df105c68"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote9bc284385e2f4c0492cfC339df105c68(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote9bc284385e2f4c0492cfC339df105c68`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "6557126e-f6b2-4731-93c6-089b20abc8c1"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage6557126eF6b2473193c6089b20abc8c1(props: any) {
  return (
    <SecondaryPage20d9df5f8b094552862a534fc106a943 parentTag="JoinUsPage6557126eF6b2473193c6089b20abc8c1" header={<SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderA0ff44144f56418dA9d4642a487daa7a" title="Join Us" />} {...props} />
  );
}

// id: "ce1890f5-0230-4535-8b49-f709b37ef196"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefCe1890f5023045358b49F709b37ef196 = "/";

// id: "381ef3de-8c1a-4108-8287-65cb2eef3ee9"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer381ef3de8c1a4108828765cb2eef3ee9(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer381ef3de8c1a4108828765cb2eef3ee9" {...props} />
  );
}

// id: "164f05c0-07e8-4f0e-80bb-f8c6b19e4ac0"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages164f05c007e84f0e80bbF8c6b19e4ac0(props: any) {
  return (
    <SecondaryPage20d9df5f8b094552862a534fc106a943 parentTag="SignupPages164f05c007e84f0e80bbF8c6b19e4ac0" header={<SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="Header2ed7c40b510d48c5A6ca019d0604e2cb" title="Join Us" />} {...props} />
  );
}

// id: "360eeb92-c8ec-4340-a611-9b2f5577a05b"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero360eeb92C8ec4340A6119b2f5577a05b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero360eeb92C8ec4340A6119b2f5577a05b hero`}>
      <div className="title">
        {props["title"] || Title85e3470d0dcb413b8da27fd585947c00}
      </div>
    </div>
  );
}

// id: "40b32098-125a-4e62-8bb6-b8ab690d9a28"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header40b32098125a4e628bb6B8ab690d9a28(props: any) {
  return (
    <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="Header40b32098125a4e628bb6B8ab690d9a28" {...props} />
  );
}

// id: "5b92f18b-6480-4c5d-b30c-27486f2be009"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage5b92f18b64804c5dB30c27486f2be009(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage5b92f18b64804c5dB30c27486f2be009`}>
      {props["header"] || <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderC68a16d990214c2b92f5591dc9674f90" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer671894784ab64e46999d06d9fdcdb769" {...props} />}
    </div>
  );
}

// id: "7f56cf41-6945-4a47-8300-4f1fbec76835"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages7f56cf4169454a4783004f1fbec76835(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages7f56cf4169454a4783004f1fbec76835`}>
      {props["header"] || <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="HeaderB54dd3888168488097bd0dfa6dfafee8" {...props} />}
      {props.children}
    </div>
  );
}

// id: "9202b872-e05e-4f7c-b467-07122d782006"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c parentTag="HeaderTop4910b1334c884714B2f7537b0a188b74" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero360eeb92C8ec4340A6119b2f5577a05b parentTag="Hero04300021C0294f80A863E39b9723d93b" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "6bae38f4-cf10-4575-a658-2df4b2f691d5"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage6bae38f4Cf104575A6582df4b2f691d5(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage6bae38f4Cf104575A6582df4b2f691d5 page`}>
      {props["header"] || <HomeHeaderBc77ec4a636440bcB8499a40a92c3604 parentTag="Header8e78f265D326488e951400e4b50dd5f9" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack3c1d7ff3Ae5b48e987eeB23d07f327a2", parentTag)} {...props} />}
      {props["body"] || <MarketingBody3bde8111A4664548Bb9626136db3678e parentTag="Body4d178fa09c53457f8c86Ea5e279bfcdd" {...props} />}
      {props["footer"] || <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="FooterEdb919fcB4604281A39a5900b0debad3" {...props} />}
    </div>
  );
}

// id: "6a3daaab-7d99-402d-8eb3-e84c404714cb"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "d33a3d34-6544-4147-883b-ba80f4a623b1"
export function Links6a3daaab7d99402d8eb3E84c404714cb(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links6a3daaab7d99402d8eb3E84c404714cb", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "04300021-c029-4f80-a863-e39b9723d93b"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "9202b872-e05e-4f7c-b467-07122d782006"
export function Hero04300021C0294f80A863E39b9723d93b(props: any) {
  return (
    <SecondaryMarketingHero360eeb92C8ec4340A6119b2f5577a05b parentTag="Hero04300021C0294f80A863E39b9723d93b" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "90780c49-a29c-4ca2-bf15-7fdd68a24f72"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a295ec01-e6be-4ed7-bd86-ee8b4403e222"
export function Footer90780c49A29c4ca2Bf157fdd68a24f72(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer90780c49A29c4ca2Bf157fdd68a24f72" {...props} />
  );
}

// id: "13934d62-0b43-421f-9057-62b19d35036f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "863c71b5-c5c9-4fcf-9158-7486e450c3b2"
export function Header13934d620b43421f905762b19d35036f(props: any) {
  return (
    <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="Header13934d620b43421f905762b19d35036f" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "df6ed971-5555-49e9-be45-eaa8fa10320b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a295ec01-e6be-4ed7-bd86-ee8b4403e222"
export function HeaderDf6ed971555549e9Be45Eaa8fa10320b(props: any) {
  return (
    <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="HeaderDf6ed971555549e9Be45Eaa8fa10320b" {...props} />
  );
}

// id: "8e78f265-d326-488e-9514-00e4b50dd5f9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6bae38f4-cf10-4575-a658-2df4b2f691d5"
export function Header8e78f265D326488e951400e4b50dd5f9(props: any) {
  return (
    <HomeHeaderBc77ec4a636440bcB8499a40a92c3604 parentTag="Header8e78f265D326488e951400e4b50dd5f9" {...props} />
  );
}

// id: "3c1d7ff3-ae5b-48e9-87ee-b23d07f327a2"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "6bae38f4-cf10-4575-a658-2df4b2f691d5"
export function WelcomeBack3c1d7ff3Ae5b48e987eeB23d07f327a2(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack3c1d7ff3Ae5b48e987eeB23d07f327a2", parentTag)} {...props} />
  );
}

// id: "344d4fff-5fd5-4122-bcc3-3120750b2e85"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "6291cf6f-f142-4505-9413-e40fac2477e2"
export function Footer344d4fff5fd54122Bcc33120750b2e85(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer344d4fff5fd54122Bcc33120750b2e85" {...props} />
  );
}

// id: "4621793a-c574-4b48-a261-7333efa22007"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "863c71b5-c5c9-4fcf-9158-7486e450c3b2"
export function Footer4621793aC5744b48A2617333efa22007(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer4621793aC5744b48A2617333efa22007" {...props} />
  );
}

// id: "edb919fc-b460-4281-a39a-5900b0debad3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "6bae38f4-cf10-4575-a658-2df4b2f691d5"
export function FooterEdb919fcB4604281A39a5900b0debad3(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="FooterEdb919fcB4604281A39a5900b0debad3" {...props} />
  );
}

// id: "a5d807e4-d47a-432e-a115-638532cb2f25"
// title: ""
// type: :reference
// key: "header"
// parent_id: "20d9df5f-8b09-4552-862a-534fc106a943"
export function HeaderA5d807e4D47a432eA115638532cb2f25(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderA5d807e4D47a432eA115638532cb2f25" title="Secondary Page" {...props} />
  );
}

// id: "788b26b3-ab52-49f0-b8df-fc9f2bcc487d"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "d44f69ff-47eb-40af-a6eb-2e7f20401773"
export const PostSlug788b26b3Ab5249f0B8dfFc9f2bcc487d = "home-page-posts2";

// id: "b54dd388-8168-4880-97bd-0dfa6dfafee8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7f56cf41-6945-4a47-8300-4f1fbec76835"
export function HeaderB54dd3888168488097bd0dfa6dfafee8(props: any) {
  return (
    <MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d parentTag="HeaderB54dd3888168488097bd0dfa6dfafee8" {...props} />
  );
}

// id: "118b361f-e40e-4084-ab9a-a4f28fab71fa"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "35d898e4-00ad-48d5-8cf5-fbe933b0ca5c"
export function LinkedLogo118b361fE40e4084Ab9aA4f28fab71fa(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo118b361fE40e4084Ab9aA4f28fab71fa", parentTag)} url="/" className="linked-logo" label={<DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label48973d381aeb469e8cdcF3147117de8b" alt="logo" />} {...props} />
  );
}

// id: "4d178fa0-9c53-457f-8c86-ea5e279bfcdd"
// title: ""
// type: :reference
// key: "body"
// parent_id: "6bae38f4-cf10-4575-a658-2df4b2f691d5"
export function Body4d178fa09c53457f8c86Ea5e279bfcdd(props: any) {
  return (
    <MarketingBody3bde8111A4664548Bb9626136db3678e parentTag="Body4d178fa09c53457f8c86Ea5e279bfcdd" {...props} />
  );
}

// id: "c68a16d9-9021-4c2b-92f5-591dc9674f90"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5b92f18b-6480-4c5d-b30c-27486f2be009"
export function HeaderC68a16d990214c2b92f5591dc9674f90(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderC68a16d990214c2b92f5591dc9674f90" title="Leveling" {...props} />
  );
}

// id: "1d4148e5-56c5-47ad-b53d-cd5bb566be2f"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "bc77ec4a-6364-40bc-b849-9a40a92c3604"
export function Hero1d4148e556c547adB53dCd5bb566be2f(props: any) {
  return (
    <HomePageHero94c7bd2917bd4bc7950eEcfd038a8e30 parentTag="Hero1d4148e556c547adB53dCd5bb566be2f" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "032ee0f7-3d04-4815-aeea-53b43e281a68"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "d44f69ff-47eb-40af-a6eb-2e7f20401773"
export const ButtonClass032ee0f73d044815Aeea53b43e281a68 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "d109fff7-01ac-4522-b381-319f06f647a7"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "a295ec01-e6be-4ed7-bd86-ee8b4403e222"
export function SecondaryNavD109fff701ac4522B381319f06f647a7(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavD109fff701ac4522B381319f06f647a7", parentTag)} {...props} />
  );
}

// id: "510ff303-0013-47ac-8e0a-5a357baf7fc6"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "d33a3d34-6544-4147-883b-ba80f4a623b1"
export function Copyright510ff303001347ac8e0a5a357baf7fc6(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright510ff303001347ac8e0a5a357baf7fc6", parentTag)} {...props} />
  );
}

// id: "845c3460-50c5-4e96-9d96-271968ffd82a"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "6e0d5a5a-5537-416b-8c6b-b378da3c7713"
export function LinkedLogo845c346050c54e969d96271968ffd82a(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo845c346050c54e969d96271968ffd82a", parentTag)} label={<DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label4cf05955666c438897b418ab711753a9" alt="logo" />} {...props} />
  );
}

// id: "2ed7c40b-510d-48c5-a6ca-019d0604e2cb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "164f05c0-07e8-4f0e-80bb-f8c6b19e4ac0"
export function Header2ed7c40b510d48c5A6ca019d0604e2cb(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="Header2ed7c40b510d48c5A6ca019d0604e2cb" title="Join Us" {...props} />
  );
}

// id: "e3137022-119e-448f-9c41-99b34e361a5b"
// title: ""
// type: :text
// key: "title"
// parent_id: "bd5c6997-74c3-4a75-943b-09217b45b02a"
export const TitleE3137022119e448f9c4199b34e361a5b = null;

// id: "f3e80951-2f4a-4c9e-84ac-985464842adf"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "6e0d5a5a-5537-416b-8c6b-b378da3c7713"
export const BackgroundImageF3e809512f4a4c9e84ac985464842adf = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "5cac7368-c4ad-4105-afe3-67bbb6837fca"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "3bde8111-a466-4548-bb96-26136db3678e"
export function MarketingCards5cac7368C4ad4105Afe367bbb6837fca(props: any) {
  return (
    <MarketingCardsD44f69ff47eb40afA6eb2e7f20401773 parentTag="MarketingCards5cac7368C4ad4105Afe367bbb6837fca" postSlug="home-page-posts2" {...props} />
  );
}

// id: "d393a591-5baa-45a2-8530-8c4749ab0d5e"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "94c7bd29-17bd-4bc7-950e-ecfd038a8e30"
export function ButtonD393a5915baa45a285308c4749ab0d5e(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonD393a5915baa45a285308c4749ab0d5e", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "63fa0359-feca-4841-8d2c-f66d61f2b838"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ad63f8cf-0e7c-4f94-ae24-a99ef7b4eeec"
export function Header63fa0359Feca48418d2cF66d61f2b838(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="Header63fa0359Feca48418d2cF66d61f2b838" title="Public Events" {...props} />
  );
}

// id: "982e2fce-97c1-4d96-a1ef-83c65c0292f3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "20d9df5f-8b09-4552-862a-534fc106a943"
export function Footer982e2fce97c14d96A1ef83c65c0292f3(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer982e2fce97c14d96A1ef83c65c0292f3" {...props} />
  );
}

// id: "67189478-4ab6-4e46-999d-06d9fdcdb769"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "5b92f18b-6480-4c5d-b30c-27486f2be009"
export function Footer671894784ab64e46999d06d9fdcdb769(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="Footer671894784ab64e46999d06d9fdcdb769" {...props} />
  );
}

// id: "3cda5487-6aa6-43ba-bc22-9708f03ca87f"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "35d898e4-00ad-48d5-8cf5-fbe933b0ca5c"
export function Navigation3cda54876aa643baBc229708f03ca87f(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation3cda54876aa643baBc229708f03ca87f", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "596da584-d3b7-4184-8ad9-321de33fa0dd"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6291cf6f-f142-4505-9413-e40fac2477e2"
export function Header596da584D3b741848ad9321de33fa0dd(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="Header596da584D3b741848ad9321de33fa0dd" title="About Us" {...props} />
  );
}

// id: "4910b133-4c88-4714-b2f7-537b0a188b74"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "9202b872-e05e-4f7c-b467-07122d782006"
export function HeaderTop4910b1334c884714B2f7537b0a188b74(props: any) {
  return (
    <DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c parentTag="HeaderTop4910b1334c884714B2f7537b0a188b74" {...props} />
  );
}

// id: "e458f42f-a8d0-46ab-b113-fdc4dd5bd80a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ad63f8cf-0e7c-4f94-ae24-a99ef7b4eeec"
export function FooterE458f42fA8d046abB113Fdc4dd5bd80a(props: any) {
  return (
    <DefaultFooterD33a3d3465444147883bBa80f4a623b1 parentTag="FooterE458f42fA8d046abB113Fdc4dd5bd80a" {...props} />
  );
}

// id: "85e3470d-0dcb-413b-8da2-7fd585947c00"
// title: ""
// type: :text
// key: "title"
// parent_id: "360eeb92-c8ec-4340-a611-9b2f5577a05b"
export const Title85e3470d0dcb413b8da27fd585947c00 = "Default Title";

// id: "d074803f-c86a-456d-ba80-cdff2a69a8a2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c52f5d70-18f1-463f-aa75-7d19544da309"
export function HeaderD074803fC86a456dBa80Cdff2a69a8a2(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderD074803fC86a456dBa80Cdff2a69a8a2" title="Resources" {...props} />
  );
}

// id: "15cd309e-d2e5-40f2-bed6-5811329ac47d"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "bc77ec4a-6364-40bc-b849-9a40a92c3604"
export const BackgroundImage15cd309eD2e540f2Bed65811329ac47d = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "012e3630-572b-4f46-a1fe-e14afa295064"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "6e0d5a5a-5537-416b-8c6b-b378da3c7713"
export function Navigation012e3630572b4f46A1feE14afa295064(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation012e3630572b4f46A1feE14afa295064", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "394de0e8-d48e-4e64-9dca-36e782f1b034"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "3e75cb32-3020-4dc2-abaa-352ebdf6d36d"
export function HeaderTop394de0e8D48e4e649dca36e782f1b034(props: any) {
  return (
    <DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c parentTag="HeaderTop394de0e8D48e4e649dca36e782f1b034" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "a0ff4414-4f56-418d-a9d4-642a487daa7a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6557126e-f6b2-4731-93c6-089b20abc8c1"
export function HeaderA0ff44144f56418dA9d4642a487daa7a(props: any) {
  return (
    <SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006 parentTag="HeaderA0ff44144f56418dA9d4642a487daa7a" title="Join Us" {...props} />
  );
}

// id: "81665fb1-739b-40f6-ace2-9a97585f90a3"
// title: ""
// type: :text
// key: "alt"
// parent_id: "9a86e4da-7318-4fc5-ae07-9f58055c685d"
export const Alt81665fb1739b40f6Ace29a97585f90a3 = "logo";

// id: "15b0380b-a40d-4483-936f-0efba2ec1a2e"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "d33a3d34-6544-4147-883b-ba80f4a623b1"
export function Groupflow15b0380bA40d4483936f0efba2ec1a2e(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow15b0380bA40d4483936f0efba2ec1a2e", parentTag)} {...props} />
  );
}

// id: "fa929628-510e-4c99-b256-2d5e307a937d"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "bd5c6997-74c3-4a75-943b-09217b45b02a"
export const NavMenuSlugFa929628510e4c99B2562d5e307a937d = "marketing-primary-nav";

// id: "e23e1adc-d1cc-4c9c-9a2f-80e9c22434a0"
// title: ""
// type: :reference
// key: "body"
// parent_id: "6291cf6f-f142-4505-9413-e40fac2477e2"
export function BodyE23e1adcD1cc4c9c9a2f80e9c22434a0(props: any) {
  return (
    <MarketingBody3bde8111A4664548Bb9626136db3678e parentTag="BodyE23e1adcD1cc4c9c9a2f80e9c22434a0" postSlug="about-us-posts" {...props} />
  );
}

// id: "0443becd-c2d8-4f0c-b320-aa885d73767f"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "6bae38f4-cf10-4575-a658-2df4b2f691d5"
export function Quote0443becdC2d84f0cB320Aa885d73767f(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote0443becdC2d84f0cB320Aa885d73767f", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "ee4a956c-1b91-43d1-9f05-14c2df007922"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "9a86e4da-7318-4fc5-ae07-9f58055c685d"
export const ImageUrlEe4a956c1b9143d19f0514c2df007922 = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qCVORAERKRFjYji_regular.png";

// id: "cc7ab457-98d3-45cc-bd1a-37c19530b1f0"
// title: ""
// type: :text
// key: "title"
// parent_id: "9202b872-e05e-4f7c-b467-07122d782006"
export const TitleCc7ab45798d345ccBd1a37c19530b1f0 = "Secondary Marketing Title 1";

// id: "69315ee7-daf6-42eb-999e-4bd8f2da67f0"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "bc77ec4a-6364-40bc-b849-9a40a92c3604"
export function HeaderTop69315ee7Daf642eb999e4bd8f2da67f0(props: any) {
  return (
    <DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c parentTag="HeaderTop69315ee7Daf642eb999e4bd8f2da67f0" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "132ba6bd-8ea4-4c89-af9e-fa6608d99c76"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "1d4148e5-56c5-47ad-b53d-cd5bb566be2f"
export const WidgetId132ba6bd8ea44c89Af9eFa6608d99c76 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "b72ffe6e-bb00-462e-91e2-c923aa881c72"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "69315ee7-daf6-42eb-999e-4bd8f2da67f0"
export const WidgetIdB72ffe6eBb00462e91e2C923aa881c72 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "76bcd946-222d-42ec-b155-2ad1bb43c37b"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "394de0e8-d48e-4e64-9dca-36e782f1b034"
export const NavMenuSlug76bcd946222d42ecB1552ad1bb43c37b = "members-primary-nav";

// id: "f6379b15-6a4b-4903-8dca-5629f0e7a033"
// title: ""
// type: :text
// key: "href"
// parent_id: "d393a591-5baa-45a2-8530-8c4749ab0d5e"
export const HrefF6379b156a4b49038dca5629f0e7a033 = "/join";

// id: "c3b3e2ef-0b5f-44b8-ba33-bc59c069efa3"
// title: ""
// type: :text
// key: "title"
// parent_id: "63fa0359-feca-4841-8d2c-f66d61f2b838"
export const TitleC3b3e2ef0b5f44b8Ba33Bc59c069efa3 = "Public Events";

// id: "47a9a524-cf4c-44c0-84a0-d434d22607e4"
// title: ""
// type: :text
// key: "label"
// parent_id: "d393a591-5baa-45a2-8530-8c4749ab0d5e"
export const Label47a9a524Cf4c44c084a0D434d22607e4 = "Join Us";

// id: "28ba292d-242e-4522-ab1c-7ba4d291c6da"
// title: ""
// type: :text
// key: "url"
// parent_id: "118b361f-e40e-4084-ab9a-a4f28fab71fa"
export const Url28ba292d242e4522Ab1c7ba4d291c6da = "/";

// id: "8d58952c-42be-48e7-9e53-2c6662a333cb"
// title: ""
// type: :text
// key: "class"
// parent_id: "3cda5487-6aa6-43ba-bc22-9708f03ca87f"
export const Class8d58952c42be48e79e532c6662a333cb = "navigation";

// id: "ed551680-2ad5-4765-b56f-4ad2b802924e"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "0443becd-c2d8-4f0c-b320-aa885d73767f"
export const ContentSlugEd5516802ad54765B56f4ad2b802924e = "home-page-quote";

// id: "e2e6c0a9-b053-440f-b5c4-2d8d5d148793"
// title: ""
// type: :text
// key: "title"
// parent_id: "a5d807e4-d47a-432e-a115-638532cb2f25"
export const TitleE2e6c0a9B053440fB5c42d8d5d148793 = "Secondary Page";

// id: "a74f95a7-e1c6-4d68-8dba-42fac1bd5387"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "6a3daaab-7d99-402d-8eb3-e84c404714cb"
export const NavMenuSlugA74f95a7E1c64d688dba42fac1bd5387 = "footer-nav";

// id: "089d6dfa-7bbd-45c8-9501-d2fe7691e761"
// title: ""
// type: :text
// key: "class"
// parent_id: "118b361f-e40e-4084-ab9a-a4f28fab71fa"
export const Class089d6dfa7bbd45c89501D2fe7691e761 = "linked-logo";

// id: "83afe112-f2f4-4101-8530-a757ff6ff4ce"
// title: ""
// type: :text
// key: "title"
// parent_id: "04300021-c029-4f80-a863-e39b9723d93b"
export const Title83afe112F2f441018530A757ff6ff4ce = "Secondary Marketing Title 2";

// id: "3a9dc701-508f-4a89-95e5-d0eb7bde703a"
// title: ""
// type: :text
// key: "title"
// parent_id: "596da584-d3b7-4184-8ad9-321de33fa0dd"
export const Title3a9dc701508f4a8995e5D0eb7bde703a = "About Us";

// id: "8b7b2a42-dae7-41f5-a354-a7840f5c7a28"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "13934d62-0b43-421f-9057-62b19d35036f"
export const NavMenuSlug8b7b2a42Dae741f5A354A7840f5c7a28 = "members-primary-nav";

// id: "1f21e11e-dcb2-423c-98ad-c12a861c8700"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3cda5487-6aa6-43ba-bc22-9708f03ca87f"
export const NavMenuSlug1f21e11eDcb2423c98adC12a861c8700 = "marketing-primary-nav";

// id: "9e579a4e-6537-4690-8e3c-9947399cf34f"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "5cac7368-c4ad-4105-afe3-67bbb6837fca"
export const PostSlug9e579a4e653746908e3c9947399cf34f = "home-page-posts2";

// id: "4cf05955-666c-4388-97b4-18ab711753a9"
// title: ""
// type: :reference
// key: "label"
// parent_id: "845c3460-50c5-4e96-9d96-271968ffd82a"
export function Label4cf05955666c438897b418ab711753a9(props: any) {
  return (
    <DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label4cf05955666c438897b418ab711753a9" {...props} />
  );
}

// id: "fa43a3c8-2498-4e77-87d5-3cb1fea61a6e"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "e23e1adc-d1cc-4c9c-9a2f-80e9c22434a0"
export const PostSlugFa43a3c824984e7787d53cb1fea61a6e = "about-us-posts";

// id: "c22b2535-e62f-4bc5-92f3-f46f68013ebd"
// title: ""
// type: :text
// key: "title"
// parent_id: "a0ff4414-4f56-418d-a9d4-642a487daa7a"
export const TitleC22b2535E62f4bc592f3F46f68013ebd = "Join Us";

// id: "6ce428fb-7a1b-4e46-b882-ce3d3e2a8343"
// title: ""
// type: :text
// key: "title"
// parent_id: "c68a16d9-9021-4c2b-92f5-591dc9674f90"
export const Title6ce428fb7a1b4e46B882Ce3d3e2a8343 = "Leveling";

// id: "307e3b0a-c97d-4682-92ea-6a2d1aac9bc1"
// title: ""
// type: :text
// key: "title"
// parent_id: "d074803f-c86a-456d-ba80-cdff2a69a8a2"
export const Title307e3b0aC97d468292ea6a2d1aac9bc1 = "Resources";

// id: "48973d38-1aeb-469e-8cdc-f3147117de8b"
// title: ""
// type: :reference
// key: "label"
// parent_id: "118b361f-e40e-4084-ab9a-a4f28fab71fa"
export function Label48973d381aeb469e8cdcF3147117de8b(props: any) {
  return (
    <DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label48973d381aeb469e8cdcF3147117de8b" {...props} />
  );
}

// id: "adca2438-ab97-4d2b-9eda-ca71e0462724"
// title: ""
// type: :text
// key: "title"
// parent_id: "2ed7c40b-510d-48c5-a6ca-019d0604e2cb"
export const TitleAdca2438Ab974d2b9edaCa71e0462724 = "Join Us";

// id: "9fac63f6-38d2-42da-a278-9733dcc83cb6"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "012e3630-572b-4f46-a1fe-e14afa295064"
export const NavMenuSlug9fac63f638d242daA2789733dcc83cb6 = "members-primary-nav";

// id: "bc77ec4a-6364-40bc-b849-9a40a92c3604"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderBc77ec4a636440bcB8499a40a92c3604(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderBc77ec4a636440bcB8499a40a92c3604 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c parentTag="HeaderTop69315ee7Daf642eb999e4bd8f2da67f0" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHero94c7bd2917bd4bc7950eEcfd038a8e30 parentTag="Hero1d4148e556c547adB53dCd5bb566be2f" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "d33a3d34-6544-4147-883b-ba80f4a623b1"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterD33a3d3465444147883bBa80f4a623b1(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterD33a3d3465444147883bBa80f4a623b1 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links6a3daaab7d99402d8eb3E84c404714cb", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoC294d5819ac14d1485aa6b8df07e4892 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright510ff303001347ac8e0a5a357baf7fc6", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow15b0380bA40d4483936f0efba2ec1a2e", parentTag)} {...props} />}
    </div>
  );
}

// id: "94c7bd29-17bd-4bc7-950e-ecfd038a8e30"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero94c7bd2917bd4bc7950eEcfd038a8e30(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero94c7bd2917bd4bc7950eEcfd038a8e30 hero`}>
      <div className="title">
        Documentation
      </div>
    </div>
  );
}

// id: "35d898e4-00ad-48d5-8cf5-fbe933b0ca5c"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo118b361fE40e4084Ab9aA4f28fab71fa", parentTag)} url="/" className="linked-logo" label={<DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label48973d381aeb469e8cdcF3147117de8b" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation3cda54876aa643baBc229708f03ca87f", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "6e0d5a5a-5537-416b-8c6b-b378da3c7713"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader6e0d5a5a5537416b8c6bB378da3c7713(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader6e0d5a5a5537416b8c6bB378da3c7713 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo845c346050c54e969d96271968ffd82a", parentTag)} label={<DefaultLogo9a86e4da73184fc5Ae079f58055c685d parentTag="Label4cf05955666c438897b418ab711753a9" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation012e3630572b4f46A1feE14afa295064", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "c294d581-9ac1-4d14-85aa-6b8df07e4892"
// title: ""
// type: :html
// key: "logo"
// parent_id: "d33a3d34-6544-4147-883b-ba80f4a623b1"
export function LogoC294d5819ac14d1485aa6b8df07e4892(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://production.groupflow.app/images/groupflow-logo-footer.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoC294d5819ac14d1485aa6b8df07e4892 logo`} />
  );
}

const Components = {
  MarketingCardsD44f69ff47eb40afA6eb2e7f20401773,
  Title545e501851b74343B6fcBd9c6fb94925,
  AdminPagesCee62baa8edb4559A114Cb89139e46f4,
  DefaultHeaderBd5c699774c34a75943b09217b45b02a,
  MembersAreaHeader3e75cb3230204dc2Abaa352ebdf6d36d,
  MarketingBody3bde8111A4664548Bb9626136db3678e,
  AboutUsPage6291cf6fF14245059413E40fac2477e2,
  PublicEventsPageAd63f8cf0e7c4f94Ae24A99ef7b4eeec,
  MemberPagesA295ec01E6be4ed7Bd86Ee8b4403e222,
  MembersAreaDefault863c71b5C5c94fcf91587486e450c3b2,
  SecondaryPage20d9df5f8b094552862a534fc106a943,
  ResourcesPageC52f5d7018f1463fAa757d19544da309,
  Label061c82851b91446f832cBf4af44724f6,
  DefaultLogo9a86e4da73184fc5Ae079f58055c685d,
  PageQuote9bc284385e2f4c0492cfC339df105c68,
  JoinUsPage6557126eF6b2473193c6089b20abc8c1,
  HrefCe1890f5023045358b49F709b37ef196,
  Footer381ef3de8c1a4108828765cb2eef3ee9,
  SignupPages164f05c007e84f0e80bbF8c6b19e4ac0,
  SecondaryMarketingHero360eeb92C8ec4340A6119b2f5577a05b,
  Header40b32098125a4e628bb6B8ab690d9a28,
  AboutLevelingPage5b92f18b64804c5dB30c27486f2be009,
  MessagePages7f56cf4169454a4783004f1fbec76835,
  SecondaryMarketingHeader9202b872E05e4f7cB46707122d782006,
  HomePage6bae38f4Cf104575A6582df4b2f691d5,
  Links6a3daaab7d99402d8eb3E84c404714cb,
  Hero04300021C0294f80A863E39b9723d93b,
  Footer90780c49A29c4ca2Bf157fdd68a24f72,
  Header13934d620b43421f905762b19d35036f,
  HeaderDf6ed971555549e9Be45Eaa8fa10320b,
  Header8e78f265D326488e951400e4b50dd5f9,
  WelcomeBack3c1d7ff3Ae5b48e987eeB23d07f327a2,
  Footer344d4fff5fd54122Bcc33120750b2e85,
  Footer4621793aC5744b48A2617333efa22007,
  FooterEdb919fcB4604281A39a5900b0debad3,
  HeaderA5d807e4D47a432eA115638532cb2f25,
  PostSlug788b26b3Ab5249f0B8dfFc9f2bcc487d,
  HeaderB54dd3888168488097bd0dfa6dfafee8,
  LinkedLogo118b361fE40e4084Ab9aA4f28fab71fa,
  Body4d178fa09c53457f8c86Ea5e279bfcdd,
  HeaderC68a16d990214c2b92f5591dc9674f90,
  Hero1d4148e556c547adB53dCd5bb566be2f,
  ButtonClass032ee0f73d044815Aeea53b43e281a68,
  SecondaryNavD109fff701ac4522B381319f06f647a7,
  Copyright510ff303001347ac8e0a5a357baf7fc6,
  LinkedLogo845c346050c54e969d96271968ffd82a,
  Header2ed7c40b510d48c5A6ca019d0604e2cb,
  TitleE3137022119e448f9c4199b34e361a5b,
  BackgroundImageF3e809512f4a4c9e84ac985464842adf,
  MarketingCards5cac7368C4ad4105Afe367bbb6837fca,
  ButtonD393a5915baa45a285308c4749ab0d5e,
  Header63fa0359Feca48418d2cF66d61f2b838,
  Footer982e2fce97c14d96A1ef83c65c0292f3,
  Footer671894784ab64e46999d06d9fdcdb769,
  Navigation3cda54876aa643baBc229708f03ca87f,
  Header596da584D3b741848ad9321de33fa0dd,
  HeaderTop4910b1334c884714B2f7537b0a188b74,
  FooterE458f42fA8d046abB113Fdc4dd5bd80a,
  Title85e3470d0dcb413b8da27fd585947c00,
  HeaderD074803fC86a456dBa80Cdff2a69a8a2,
  BackgroundImage15cd309eD2e540f2Bed65811329ac47d,
  Navigation012e3630572b4f46A1feE14afa295064,
  HeaderTop394de0e8D48e4e649dca36e782f1b034,
  HeaderA0ff44144f56418dA9d4642a487daa7a,
  Alt81665fb1739b40f6Ace29a97585f90a3,
  Groupflow15b0380bA40d4483936f0efba2ec1a2e,
  NavMenuSlugFa929628510e4c99B2562d5e307a937d,
  BodyE23e1adcD1cc4c9c9a2f80e9c22434a0,
  Quote0443becdC2d84f0cB320Aa885d73767f,
  ImageUrlEe4a956c1b9143d19f0514c2df007922,
  TitleCc7ab45798d345ccBd1a37c19530b1f0,
  HeaderTop69315ee7Daf642eb999e4bd8f2da67f0,
  WidgetId132ba6bd8ea44c89Af9eFa6608d99c76,
  WidgetIdB72ffe6eBb00462e91e2C923aa881c72,
  NavMenuSlug76bcd946222d42ecB1552ad1bb43c37b,
  HrefF6379b156a4b49038dca5629f0e7a033,
  TitleC3b3e2ef0b5f44b8Ba33Bc59c069efa3,
  Label47a9a524Cf4c44c084a0D434d22607e4,
  Url28ba292d242e4522Ab1c7ba4d291c6da,
  Class8d58952c42be48e79e532c6662a333cb,
  ContentSlugEd5516802ad54765B56f4ad2b802924e,
  TitleE2e6c0a9B053440fB5c42d8d5d148793,
  NavMenuSlugA74f95a7E1c64d688dba42fac1bd5387,
  Class089d6dfa7bbd45c89501D2fe7691e761,
  Title83afe112F2f441018530A757ff6ff4ce,
  Title3a9dc701508f4a8995e5D0eb7bde703a,
  NavMenuSlug8b7b2a42Dae741f5A354A7840f5c7a28,
  NavMenuSlug1f21e11eDcb2423c98adC12a861c8700,
  PostSlug9e579a4e653746908e3c9947399cf34f,
  Label4cf05955666c438897b418ab711753a9,
  PostSlugFa43a3c824984e7787d53cb1fea61a6e,
  TitleC22b2535E62f4bc592f3F46f68013ebd,
  Title6ce428fb7a1b4e46B882Ce3d3e2a8343,
  Title307e3b0aC97d468292ea6a2d1aac9bc1,
  Label48973d381aeb469e8cdcF3147117de8b,
  TitleAdca2438Ab974d2b9edaCa71e0462724,
  NavMenuSlug9fac63f638d242daA2789733dcc83cb6,
  HomeHeaderBc77ec4a636440bcB8499a40a92c3604,
  DefaultFooterD33a3d3465444147883bBa80f4a623b1,
  HomePageHero94c7bd2917bd4bc7950eEcfd038a8e30,
  DefaultHeaderTop35d898e400ad48d58cf5Fbe933b0ca5c,
  ShortHeader6e0d5a5a5537416b8c6bB378da3c7713,
  LogoC294d5819ac14d1485aa6b8df07e4892
}

export default Components;