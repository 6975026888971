const ROUTES = {
  "/": {
    "name": "HomePage6bae38f4Cf104575A6582df4b2f691d5",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesCee62baa8edb4559A114Cb89139e46f4",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage6291cf6fF14245059413E40fac2477e2",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage5b92f18b64804c5dB30c27486f2be009",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageAd63f8cf0e7c4f94Ae24A99ef7b4eeec",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage6557126eF6b2473193c6089b20abc8c1",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesA295ec01E6be4ed7Bd86Ee8b4403e222",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages7f56cf4169454a4783004f1fbec76835",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPageC52f5d7018f1463fAa757d19544da309",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages164f05c007e84f0e80bbF8c6b19e4ac0",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;